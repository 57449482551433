import React from 'react';
import { useInView } from 'react-intersection-observer';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        color: '#333',
        fontSize: '16px',
        padding: '40px 20px',
        textAlign: 'left',
        transition: 'opacity 0.5s ease-out',
        opacity: 0.1,
        maxWidth: '1400px',
        margin: 'auto',
        position: 'relative',
    },
    grid: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    column: {
        flex: '1 1 45%',
        marginBottom: '40px',
        padding: '0 20px',
    },
    heading: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
    },
    subheading: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    text: {
        marginBottom: '10px',
    },
    listItem: {
        marginBottom: '5px',
    },
    divider: {
        width: '100%',
        height: '2px',
        backgroundColor: '#333',
        margin: '20px 0',
    },
    vdivider: {
        width: '2px',
        height: 'auto',
        backgroundColor: '#333',
        margin: '0 20px',
    },
    keyframes: `@keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }`,
};

function Experience() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.6,
    });

    return (
        <div style={{ position: 'relative' }}>
            <style>{styles.keyframes}</style>
            <div style={{ ...styles.container, opacity: inView ? 1 : 0.1 }} ref={ref}>
                <h1 style={styles.heading}>Experience & Skills</h1>
                <div style={styles.divider}></div>
                <div style={styles.grid}>
                    <div style={styles.column}>
                        <h2 style={styles.subheading}>Professional Experience</h2>
                        <p style={styles.text}><strong>Telstra (2019 - Present)</strong></p>
                        <p style={styles.text}>Tech Bar Advisor / Sales Consultant</p>
                        <ul>
                            <li style={styles.listItem}>Providing technical support and advice to customers</li>
                            <li style={styles.listItem}>Showcasing the latest technology and developing customized solutions</li>
                            <li style={styles.listItem}>Managing stock and inventory, team, and store operations</li>
                            <li style={styles.listItem}>Ensuring compliance and maintaining a high level of customer satisfaction</li>
                            <li style={styles.listItem}>Meeting KPIs and achieving sales targets</li>
                            <li style={styles.listItem}>Recognized as Employee of the Month 19 times</li>
                        </ul>
                        <p style={styles.text}><strong>Lumo Energy (2018 - 2019)</strong></p>
                        <p style={styles.text}>Door-to-Door Sales Representative</p>
                        <ul>
                            <li style={styles.listItem}>Conducting outbound sales and bill analysis</li>
                            <li style={styles.listItem}>Managing the Fuel Provider Takeover Procedure</li>
                            <li style={styles.listItem}>Converting leads into sales and performing customer follow-ups</li>
                            <li style={styles.listItem}>Maintaining detailed records of customer interactions</li>
                            <li style={styles.listItem}>Achieving sales targets and KPIs</li>
                        </ul>
                        <p style={styles.text}><strong>Alabenlo Music (2017 - 2018)</strong></p>
                        <p style={styles.text}>Customer and Service Consultant</p>
                        <ul>
                            <li style={styles.listItem}>Providing customer service and advice on music instruments</li>
                            <li style={styles.listItem}>Managing stock and inventory</li>
                            <li style={styles.listItem}>Ensuring high levels of customer satisfaction</li>
                            <li style={styles.listItem}>Assisting in product selection and purchase decisions</li>
                        </ul>
                    </div>
                    <div style={styles.vdivider}></div> 
                    <div style={styles.column}>
                        <h2 style={styles.subheading}>Education</h2>
                        <p style={styles.text}><strong>RMIT University (2017 - 2025 Expected)</strong></p>
                        <p style={styles.text}>Bachelor of Software Engineering</p>
                        <p style={styles.text}>Specializing in Full Stack Web/Software Development, Data Science, Machine Learning, and Cloud Computing</p>
                        <p style={styles.text}><strong>Dandenong High School (2013 - 2016)</strong></p>
                        <p style={styles.text}>Secondary Education - VCE</p>
                        <p style={styles.text}>Subjects: Specialist Mathematics, Mathematical Methods, Physics, Chemistry, English</p>
                        <div style={styles.divider}></div>
                        <h2 style={styles.subheading}>Technical Skills</h2>
                        <p style={styles.text}>Languages: Java, C#, C++, Python, TypeScript, JavaScript, HTML, CSS, PHP</p>
                        <p style={styles.text}>Frameworks: Spring Boot, .NET Core, React, Node.js, Vue.js, Angular</p>
                        <p style={styles.text}>Databases: SQL, MongoDB, PostgreSQL, DynamoDB, NoSQL, JDBC, Firebase</p>
                        <p style={styles.text}>Cloud: AWS (EC2, S3, RDS, Lambda), Azure, Google Cloud Platform</p>
                        <p style={styles.text}>DevOps: Docker, CircleCI, Git, GitHub, GitLab</p>
                        <p style={styles.text}>Other: REST APIs, GraphQL, Agile methodologies, Microservices, TDD</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;
